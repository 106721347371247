<template>
  <div class="introduce-style">
    <div class="bigbox">
      <div class="box1">{{$t('text.t163')}}</div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t106')}}</div>
      <div class="bb-inp bb-mb20">
        <el-select v-model="standard" :placeholder="$t('text.t164')">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t104')}}</div>
      <div class="bb-inp bb-mb20">
        <el-input v-model="address" :placeholder="$t('text.t165')"></el-input>
      </div>
      <div class="bb-mb20">
        <van-button :loading="isloading" block color="#323232" @click="check">{{$t('text.t166')}}</van-button>
      </div>
      <div class="nftlist">
        <div class="b1 bigbox-mb10 between-center">
          <div class="t1">{{$t('text.t37')}}</div>
          <div class="t2">{{$t('text.t127')}}：{{nftnum}}{{$t('text.t153')}}</div>
        </div>
        <div class="b2 bb-mb20 start-center">
          <div class="b2-item bb-mb20" v-for="(item,i) in nftlist" :key="i">
            <img src="@/assets/img/img60.png" alt="" class="b2-item-img" />
            <div class="b2-item-t1">#{{item.tokenId}} x{{item.tokenAmount}}</div>
          </div>
        </div>
      </div>
      <div class="bigbox-t1 bigbox-mb10">{{$t('text.t167')}}</div>
      <div class="bb-inp bb-mb20">
        <el-select v-model="heji" :placeholder="$t('text.t168')" @change="schange">
          <el-option
            v-for="item in collectionList"
            :key="item.id"
            :label="item.collectionname"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="bb-btn">
        <van-button :loading="isloading" block color="#323232" @click="addNft">{{$t('text.t169')}}</van-button>
      </div>
    </div>
  </div>
</template>
<script>
import {mapState,mapActions} from 'vuex'
export default {
  components: {},
  data() {
    return {
      standard: "",
      address: "",
      options: [
        {
          value: "0",
          label: "ERC-721",
        },
        {
          value: "1",
          label: "ERC-1155",
        },
      ],
      heji: "",
      whitelist:["Createcollection"],
      isloading:false,
      nftlist:[],
      nftnum:0
    };
  },
  computed: {
    ...mapState(["collectionList","language"]),
  },
  created(){
    this.getMyCollection(0)
  },
  mounted() {},
  methods: {
    ...mapActions(["getMyCollection"]),
    addNft(){
      if(!this.standard){
        this.$toast(this.$t('text.t164'))
        return
      }
      if(!this.address){
        this.$toast(this.$t('text.t165'))
        return
      }
      if(!this.nftnum){
        this.$toast(this.$t('text.t170'))
        return
      }
      if(!this.heji){
        this.$toast(this.$t('text.t168'))
        return
      }
      this.isloading = true
      let arg = {
        contract:this.address,
        tokenType:this.standard,
        collection_id:this.heji,
        language:this.language
      }
      this.$http.post('/collection/oipTokens',arg).then(res=>{
        this.$toast(res.msg)
        this.address = ''
        this.standard = ''
        this.heji = ''
        this.nftlist = []
        this.nftnum = 0
        this.isloading = false
      }).catch(()=>{
        this.isloading = false
      })
    },
    check(){
      if(!this.standard){
        this.$toast(this.$t('text.t164'))
        return
      }
      if(!this.address){
        this.$toast(this.$t('text.t165'))
        return
      }
      this.nftlist = []
      this.nftnum = 0
      this.isloading = true
      this.$http.get('/collection/oipToken',{
        contract:this.address,
        tokenType:this.standard,
        language:this.language
      }).then(res=>{
        const {count} = res.data
        this.nftlist = count
        // this.nftnum = count.map(ele=>ele.tokenAmount).reduce((a,b)=>a+b)
        this.nftnum = number
        console.log(this.nftnum);
        this.isloading = false
      }).catch(()=>{
        this.isloading = false
      })
    },
    schange(e){
      if(e == -1){
        this.heji = ''
        this.goAhead('/createcollection?type=introduce') 
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    console.log(this.whitelist.includes(to.name));
    if(this.whitelist.includes(to.name)){
      from.meta.keepAlive = true
    } else{
      from.meta.keepAlive = false
    }
    next()
  },
};
</script>

<style lang="less">
.introduce-style {
  .bigbox {
    width: 321px;
    padding: 41px 0;
    margin: auto;
    .box1 {
      text-align: center;
      margin-bottom: 25px;
      font-size: 18px;
      font-weight: bold;
      color: #323232;
    }
    .bigbox-t1 {
      font-size: 15px;
      font-weight: bold;
      color: #323232;
    }
    .bb-mb20 {
      margin-bottom: 20px;
    }
    .bigbox-mb10 {
      margin-bottom: 10px;
    }
    .bb-inp {
      width: 100%;
      .el-select {
        display: block;
      }
    }
    .bb-btn {
      width: 163px;
      margin: auto;
    }
    .van-button {
      border-radius: 5px;
    }
    .nftlist {
      .b1 {
        .t1 {
          font-size: 15px;
          font-weight: bold;
          color: #323232;
        }
        .t2 {
          font-size: 12px;
          font-weight: 500;
          color: #323232;
        }
      }
      .b2 {
        background: #f4f4f4;
        border: 1px solid #e1e1e1;
        border-radius: 5px;
        flex-wrap: wrap;
        padding: 11px 0;
        .b2-item {
          width: 25%;
          text-align: center;
          .b2-item-img {
            width: 50px;
            height: 50px;
            margin-bottom: 12px;
          }
          .b2-item-t1 {
            font-size: 10px;
            font-weight: 500;
            color: #323232;
          }
        }
      }
    }
  }
}
</style>